import { Fab } from "@mui/material";
import React from "react";

interface ShiftCalendarViewSwitcherProps {
  onClick: () => void;
  children: React.ReactNode;
}

export function ShiftCalendarViewSwitcher(props: ShiftCalendarViewSwitcherProps) {
  const { onClick, children } = props;
  return (
    <Fab
      variant="extended"
      sx={{
        position: "absolute",
        bottom: "24px",
        left: "50%",
        transform: "translateX(-50%)",
      }}
      color="primary"
      size="medium"
      onClick={onClick}
    >
      {children}
    </Fab>
  );
}
