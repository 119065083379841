import "./style.scss";
import { App, AppState } from "@capacitor/app";
import { isDefined } from "@clipboard-health/util-ts";
import { IonAlert, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import DateRangeIcon from "@mui/icons-material/DateRange";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import { Box, Stack, Tab, Tabs } from "@mui/material";
import { OpenNotificationCenterIcon } from "@src/app/notificationCenter/openNotificationCenterIcon";
import { useGetRecentColleagues } from "@src/appV2/Agents/api/useGetRecentColleagues";
import { RootPaths } from "@src/appV2/App/paths";
import { FindCasesPage } from "@src/appV2/ExperimentalHomeHealth/FindCases/FindCasesPage";
import { useIsHomeHealthEnabled } from "@src/appV2/ExperimentalHomeHealth/lib/useIsHomeHealthEnabled";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { useSearchParams } from "@src/appV2/lib/utils/useSearchParams";
import { useGetLicensesCount } from "@src/appV2/Licenses/api/useGetLicensesCount";
import {
  OpenShiftCalendar as OpenShiftCalendarV2,
  UrgentShiftsBanner,
} from "@src/appV2/OpenShifts";
import { GET_AGENT_SHIFTS_URL } from "@src/appV2/OpenShifts/api/useAgentShifts";
import { GET_OPEN_SHIFT_COUNT_URL } from "@src/appV2/OpenShifts/api/useOpenShiftCount";
import { WorkerBanners } from "@src/appV2/OpenShifts/Banners/WorkerBanners";
import { DebitCardExpirationBanner } from "@src/appV2/OpenShifts/components/DebitCardExpirationBanner/DebitCardExpirationBanner";
import { PlacementCandidatePage } from "@src/appV2/PlacementCandidate/Page";
import { useIsJobsEnabled } from "@src/appV2/PlacementCandidate/useIsJobsEnabled";
import { ShiftBlockPage } from "@src/appV2/ShiftBlocks/Page";
import { ShiftBookingType } from "@src/appV2/ShiftBlocks/utils";
import { SHIFT_DISCOVERY_PATH } from "@src/appV2/ShiftDiscovery/paths";
import { useIsShiftDiscoveryEnabled } from "@src/appV2/ShiftDiscovery/utils/useIsShiftDiscoveryEnabled";
import { PendingShiftInvitesBanner } from "@src/appV2/Shifts/ShiftInvites/PendingShiftInvitesBanner";
import { useUrgentShifts } from "@src/appV2/Shifts/UrgentShifts/api/useUrgentShifts";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useUpdateWorkerCache } from "@src/appV2/Worker/useUpdateWorkerCache";
import { OnboardingIncentiveBanner } from "@src/appV2/WorkerOnboarding/OnboardingIncentiveBanner";
import { MioStages } from "@src/appV2/WorkerOnboarding/types";
import { MessageString } from "@src/lib/constants";
import { useQueryClient } from "@tanstack/react-query";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";

import {
  removeRecommendedDistance,
  sendCalendarOpenActivity,
  updateDistancePreference,
} from "./api";
import { useOpenShiftContext } from "./context";
import { OpenShiftMap } from "./openShiftMap";
import { ShiftCalendarViewSwitcher } from "./ShiftCalendarViewSwitcher";
import UrgentShiftPermissionContainer from "./urgentShifts/urgentShiftPermissionContainer";
import { USER_EVENTS } from "../../constants";
import { AddressAndDistance } from "../addressAndDistance";
import { HeaderMessagesIcon } from "../hcpShifts/headerMessagesIcon";
import { DISMISSED_STRIPE_POPUP, IS_SIGNUP } from "../onboardingStripe/constants";
import { useShouldBeOnboarded } from "../onboardingStripe/hooks";
import { TabRouterPath } from "../routing/constant/tabRoute";
import { useSession } from "../store/helperHooks";
import { updateRecentColleaguesList } from "../store/recentColleagues/actions";

interface OpenShiftsModalsAndAlerts {
  showAddressAlert: boolean;
  enterAddressModalOpen: boolean;
  showDistancePreferenceAlert: boolean;
  showAddLicenseAlert: boolean;
  showPendingLicenseAlert: boolean;
}

export function OpenShifts() {
  const { segmentView, setSegmentView, shiftBookingType, setShiftBookingType } =
    useOpenShiftContext();
  const { shiftBookingTab: shiftBookingTabQueryParams } = useSearchParams();

  const dispatch = useDispatch();
  const history = useHistory();
  const { path: currentRouterUrl } = useRouteMatch();
  const [hideUrgentShiftsBanner, setHideUrgentShiftsBanner] = useState<boolean>(false);
  const [appIsActive, setAppIsActive] = useState(false);

  const worker = useDefinedWorker();
  const updateWorkerCache = useUpdateWorkerCache();
  const isShiftDiscoveryEnabled = useIsShiftDiscoveryEnabled();

  const { popups, isSignup } = useSession();
  const { visible: shouldShowICAgreementPopup = false } = popups?.ICAgreement ?? {};

  const [modalsAndAlerts, setModalsAndAlerts] = useState<OpenShiftsModalsAndAlerts>({
    showAddressAlert: false,
    enterAddressModalOpen: false,
    showDistancePreferenceAlert: false,
    showAddLicenseAlert: false,
    showPendingLicenseAlert: false,
  });

  const shouldBeOnboarded = useShouldBeOnboarded();

  const toggleModalVisible = (modal: keyof OpenShiftsModalsAndAlerts, visible: boolean) => {
    setModalsAndAlerts((previousState) => ({
      ...previousState,
      [modal]: visible,
    }));
  };

  useLogEffect(USER_EVENTS.VIEWED_OPEN_SHIFTS, {
    activeTab: localStorage.getItem("savedShiftBookingType") ?? ShiftBookingType.PER_DIEM,
  });

  const isHomeHealthEnabled = useIsHomeHealthEnabled();
  const shiftBlockBookingFlag = useCbhFlag(CbhFeatureFlag.ROLLOUT_SHIFT_BLOCK_BOOKING, {
    defaultValue: {
      isBlockBookingEnabled: false,
    },
  });
  const isJobsEnabled = useIsJobsEnabled();

  useEffect(() => {
    if (isDefined(shiftBookingTabQueryParams)) {
      setShiftBookingType(shiftBookingTabQueryParams as string);
      return;
    }

    const savedShiftBookingType = localStorage.getItem("savedShiftBookingType");

    if (shiftBlockBookingFlag.isBlockBookingEnabled && savedShiftBookingType === "block-booking") {
      setShiftBookingType("block-booking");
    } else if (isHomeHealthEnabled && savedShiftBookingType === "home-health") {
      setShiftBookingType("home-health");
    } else if (isJobsEnabled && savedShiftBookingType === "jobs") {
      setShiftBookingType("jobs");
    } else {
      setShiftBookingType("per-diem");
    }
  }, [
    isHomeHealthEnabled,
    setShiftBookingType,
    shiftBlockBookingFlag.isBlockBookingEnabled,
    shiftBookingTabQueryParams,
    isJobsEnabled,
  ]);

  useEffect(() => {
    /**
     * FIXME - Refactor this effect to use `useLogEvent`.
     * Use URL based state management and convert localStorage to URL state management.
     * This useEffect should not exist.
     */
    const savedFindShiftsSegment = localStorage.getItem("savedFindShiftsSegment");
    if (savedFindShiftsSegment === "map") {
      setSegmentView("map");
    } else {
      setSegmentView("calendar");
    }

    if (worker && isEmpty(worker.geoLocation)) {
      toggleModalVisible("showAddressAlert", true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSegmentView]);

  useEffect(() => {
    /**
     * FIXME - convert to react-query.
     * This useEffect should not exist.
     */
    if (!worker.preference?.recommendedDistance) {
      return;
    }

    if ((worker.preference?.recommendedDistance || 0) <= (worker.preference.distance || 0)) {
      const remove = async () => await removeRecommendedDistance();
      remove();
      return;
    }
    toggleModalVisible("showDistancePreferenceAlert", true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [worker.preference?.recommendedDistance]);

  const { isLoading: isLoadingRecentColleagues, data: recentColleaguesData } =
    useGetRecentColleagues({ enabled: !worker.hideAsColleagueOnShifts });

  const queryClient = useQueryClient();

  // For business logic refer https://clipboardhealth.atlassian.net/wiki/spaces/EPP/pages/2457534599/Stripe+onboarding+popup
  useEffect(() => {
    const dismissedStripePopup = localStorage.getItem(DISMISSED_STRIPE_POPUP);
    if (shouldBeOnboarded && !isSignup && !dismissedStripePopup) {
      localStorage.setItem(IS_SIGNUP, "false");
      history.replace(TabRouterPath.PAYMENT_SERVICE_ONBOARDING);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    logNoAddressForHCPEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalsAndAlerts.showAddressAlert]);

  const logNoAddressForHCPEvent = () => {
    if (modalsAndAlerts.showAddressAlert) {
      logEvent(USER_EVENTS.NO_SHIFT_ALERT, {
        "No Address For HCP": MessageString.noAddressForHCP,
      });
    }
  };

  useEffect(() => {
    if (worker.userId) {
      sendCalendarOpenActivity();
    }
  }, [worker.userId]);

  const onStateChange = (state: AppState) => {
    setAppIsActive((value) => (state.isActive !== value ? state.isActive : value));
  };

  useEffect(() => {
    const listener = App.addListener("appStateChange", onStateChange);

    return () => {
      listener.then((listenerHandle) => listenerHandle.remove());
    };
  }, []);
  useEffect(() => {
    if (appIsActive) {
      setHideUrgentShiftsBanner(false);
      /**
       * FIXME, convert the refetchUrgentShifts to use react-query's option `refetchOnWindowFocus: true`
       */
      refetchUrgentShifts();
      sendCalendarOpenActivity();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appIsActive]);

  useEffect(() => {
    dispatch(updateRecentColleaguesList(recentColleaguesData ?? null, isLoadingRecentColleagues));
  }, [dispatch, recentColleaguesData, isLoadingRecentColleagues]);

  const { data: licensesCount } = useGetLicensesCount({
    workerId: String(worker.userId),
  });

  useEffect(() => {
    if (!isDefined(licensesCount)) {
      return;
    }

    if (
      (!licensesCount?.ACTIVE || licensesCount.ACTIVE <= 0) &&
      (!licensesCount?.EXPIRING || licensesCount.EXPIRING <= 0)
    ) {
      if (!licensesCount?.PENDING || licensesCount.PENDING <= 0) {
        toggleModalVisible("showAddLicenseAlert", true);
      } else {
        toggleModalVisible("showPendingLicenseAlert", true);
      }
    }
  }, [licensesCount]);

  const {
    data: urgentShiftsData,
    isSuccess: isUrgentShiftsSuccessful,
    refetch: refetchUrgentShifts,
  } = useUrgentShifts(
    {
      coordinates: worker.geoLocation?.coordinates,
      getCountOnly: true,
    },
    {
      enabled:
        isDefined(worker.geoLocation?.coordinates) &&
        hideUrgentShiftsBanner === false &&
        Boolean(segmentView),
    }
  );

  const urgentShiftsIsAvailable = isUrgentShiftsSuccessful ? urgentShiftsData.total > 0 : false;

  const showUrgentShiftsBanner = !hideUrgentShiftsBanner && urgentShiftsIsAvailable;

  const goToAddLicensePage = () => {
    history.push(TabRouterPath.ADD_LICENSE);
  };

  const goToLicensesPage = () => {
    history.push(TabRouterPath.LICENSE_MANAGER);
  };

  const closeAddressAlertModal = () => toggleModalVisible("showAddressAlert", false);
  const openEnterAddressModal = () => {
    toggleModalVisible("showAddressAlert", false);
    toggleModalVisible("enterAddressModalOpen", true);
  };

  const updateRecDistance = async (okay: boolean) => {
    if (okay) {
      const recDist = worker.preference?.recommendedDistance;
      const updatedWorker = await updateDistancePreference(Number(recDist));
      updateWorkerCache(updatedWorker);
    }
    await removeRecommendedDistance();
    toggleModalVisible("showDistancePreferenceAlert", true);
  };

  return (
    <div>
      <IonPage>
        <IonHeader no-border>
          <OnboardingIncentiveBanner workerId={worker.userId} stage={MioStages.FINAL} />
          <IonToolbar mode="ios">
            <OpenNotificationCenterIcon />

            <IonTitle>{isJobsEnabled ? "Find Work" : "Find Shifts"}</IonTitle>
            <HeaderMessagesIcon />
          </IonToolbar>
        </IonHeader>
        <DebitCardExpirationBanner />
        {showUrgentShiftsBanner ? (
          <UrgentShiftsBanner
            onClose={() => {
              setHideUrgentShiftsBanner(true);
            }}
          />
        ) : null}
        {(isHomeHealthEnabled || shiftBlockBookingFlag.isBlockBookingEnabled) &&
          shiftBookingType && (
            <Tabs
              centered
              value={shiftBookingType}
              variant="fullWidth"
              onChange={(_, value) => {
                logEvent(APP_V2_USER_EVENTS.OPEN_SHIFTS_TAB_LAYOUT, {
                  activeTab: value,
                  action: "tab-change",
                  location: currentRouterUrl,
                });
                localStorage.setItem("savedShiftBookingType", value);
                setShiftBookingType(value);

                if (value === "per-diem" && isShiftDiscoveryEnabled) {
                  history.push(`${RootPaths.APP_V2_HOME}/${SHIFT_DISCOVERY_PATH}`);
                }
              }}
            >
              <Tab label="Per Diem" value="per-diem" />
              {shiftBlockBookingFlag.isBlockBookingEnabled && (
                <Tab label="Blocks" value="block-booking" />
              )}
              {isHomeHealthEnabled && <Tab label="Home Health" value="home-health" />}
              {isJobsEnabled && <Tab label="Jobs" value="jobs" />}
            </Tabs>
          )}
        {shiftBookingType === "per-diem" && segmentView === "map" && (
          <>
            <IonContent>
              <OpenShiftMap segmentView={segmentView} />

              <ShiftCalendarViewSwitcher
                onClick={() => {
                  queryClient.invalidateQueries([GET_OPEN_SHIFT_COUNT_URL]);
                  queryClient.invalidateQueries([GET_AGENT_SHIFTS_URL]);

                  // Saved last-visited even user logout and login again
                  localStorage.removeItem("savedFindShiftsSegment");
                  setSegmentView("calendar");
                }}
              >
                <DateRangeIcon sx={{ marginRight: 1 }} /> Calendar view
              </ShiftCalendarViewSwitcher>
            </IonContent>
            <PendingShiftInvitesBanner />
          </>
        )}
        {shiftBookingType === "per-diem" && segmentView === "calendar" && (
          <>
            <IonContent>
              <Box
                sx={{
                  paddingX: {
                    xs: 0,
                    md: 2,
                  },
                  paddingBottom: 10,
                  height: "100%",
                  overflow: "auto",
                  backgroundColor: (theme) => theme.palette.background.paper,
                }}
              >
                <OpenShiftCalendarV2 agent={worker} refetchUrgentShifts={refetchUrgentShifts} />
              </Box>

              <ShiftCalendarViewSwitcher
                onClick={() => {
                  localStorage.setItem("savedFindShiftsSegment", "map");
                  setSegmentView("map");
                }}
              >
                <MapOutlinedIcon sx={{ marginRight: 1 }} /> Map view
              </ShiftCalendarViewSwitcher>
            </IonContent>
            <Stack spacing={1}>
              <PendingShiftInvitesBanner />
              <WorkerBanners />
            </Stack>
          </>
        )}
        {shiftBlockBookingFlag.isBlockBookingEnabled && shiftBookingType === "block-booking" && (
          <ShiftBlockPage agent={worker} />
        )}
        {isHomeHealthEnabled &&
          (shiftBookingType === "home-health" || segmentView === "home-health") && (
            <FindCasesPage />
          )}

        {shiftBookingType === "jobs" && <PlacementCandidatePage worker={worker} />}
      </IonPage>

      <UrgentShiftPermissionContainer />
      <IonAlert
        isOpen={modalsAndAlerts.showAddressAlert}
        header="Where are you?"
        message={`We need your address to show you shifts near you.`}
        backdropDismiss={false}
        buttons={[
          {
            text: "Later",
            handler: closeAddressAlertModal,
          },
          {
            text: "Set my address",
            handler: openEnterAddressModal,
          },
        ]}
      />
      <AddressAndDistance
        isOpen={modalsAndAlerts.enterAddressModalOpen}
        closeModal={() => toggleModalVisible("enterAddressModalOpen", false)}
      />

      {/* modal to suggest change of recommended distance */}
      <IonAlert
        isOpen={modalsAndAlerts.showDistancePreferenceAlert}
        onDidDismiss={() => toggleModalVisible("showDistancePreferenceAlert", false)}
        header="Distance Preference"
        message={`Because we’ve improved how we calculate driving distance, some
          facilities where you’ve worked recently are now out of range, so you won’t
          see their shifts. To see them again, we recommend increasing your Distance
          Preference to ${worker.preference?.recommendedDistance} miles.
          You can change this again at any time, but would you like us to do it for you now?`}
        buttons={[
          {
            text: "No thanks",
            role: "cancel",
            handler: () => {
              updateRecDistance(false);
            },
          },
          {
            text: "Yes please",
            handler: () => {
              updateRecDistance(true);
            },
          },
        ]}
      />
      {/* modal to alert user to add license */}
      <IonAlert
        isOpen={modalsAndAlerts.showAddLicenseAlert && !shouldShowICAgreementPopup}
        header="Add a License"
        message={`You can’t see any shifts because you don’t have any active licenses. Would you like to add one now and start looking for shifts?`}
        backdropDismiss={false}
        data-testid="add-license-alert"
        buttons={[
          {
            text: "Not now",
            handler: () => {
              toggleModalVisible("showAddLicenseAlert", false);
            },
          },
          {
            text: "Add a License",
            handler: goToAddLicensePage,
          },
        ]}
      />

      {/* modal to alert user about pending license */}
      <IonAlert
        isOpen={modalsAndAlerts.showPendingLicenseAlert && !shouldShowICAgreementPopup}
        header="License pending"
        message={`We’re working on verifying your licenses. We’ll send you a notification when we’ve finished!`}
        backdropDismiss={false}
        buttons={[
          {
            text: "Dismiss",
            handler: () => {
              toggleModalVisible("showPendingLicenseAlert", false);
            },
          },
          {
            text: "Licenses",
            handler: goToLicensesPage,
          },
        ]}
      />
    </div>
  );
}
