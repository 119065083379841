import constate from "constate";
import { useState } from "react";

function useOpenShift() {
  /**
   * @deprecated
   * This global state should be moved to the route.
   * The goal of this is to deep link to either a map or calendar view.
   */
  const [segmentView, setSegmentView] = useState<string>("");
  /**
   * @deprecated
   * This global state should be moved to the route.
   * The goal of this is to deep link to either a per diem or blocks view.
   */
  const [shiftBookingType, setShiftBookingType] = useState<string>();
  const [calendarDate, setCalendarDate] = useState(new Date());
  return {
    segmentView,
    setSegmentView,
    shiftBookingType,
    setShiftBookingType,
    calendarDate,
    setCalendarDate,
  };
}

export const [OpenShiftProvider, useOpenShiftContext] = constate(useOpenShift);
